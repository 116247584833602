<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton :classProp="'primary'" :isDisabled="!isDirty" @vasionButtonClicked="save()">
          Save
        </VasionButton>
        <VasionButton class="last-btn" :classProp="'secondary'" @vasionButtonClicked="goToRetentionList()">
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <VasionSnackbar
        id="edit-retention-snackbar"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
      <AutomationEngineSharedHeader
        ref="header"
        nameLabel="RETENTION NAME"
        @permissions="showMainSection = false"
        @general-automation="showMainSection = true"
        @dirty="isDirty = true"
      />
      <div v-if="showMainSection" id="build-retention" class="collapsable-section">
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showBuildRetention ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="showBuildRetention = !showBuildRetention"
        />
        <h2 class="subheader">
          Build Retention
        </h2>
      </div>
      <div v-if="showMainSection" v-show="showBuildRetention">
        <div class="radio-label">
          <label class="vasion-input-label-top">DOCUMENT WITH</label>
        </div>
        <div class="row">
          <md-radio
            v-model="documentWithType"
            class="width180"
            value="createdDate"
            @change="isDirty = true"
          >
            Created Date
          </md-radio>
          <md-radio v-model="documentWithType" value="dateField" @change="isDirty = true">
            Date Field
          </md-radio>
        </div>
        <div v-if="documentWithType === 'dateField'" class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="documentWithObject"
            :dataArray="attributeFormList"
            width="302"
            :title="`${$formsLabel.toUpperCase()}`"
            placeholder="..."
            type="plain-list"
            displayName="name"
            style="padding-right: 13px"
            valueName="value"
            @input="isDirty = true"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="documentWithDateField"
            title="FIELD"
            :dataArray="dateIndexFields"
            :showSearchField="true"
            style="padding-right: 13px"
            width="302"
            type="plain-list"
            displayName="name"
            valueName="value"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div class="radio-label">
          <label class="vasion-input-label-top radio-label">DESTINATION</label>
        </div>
        <div class="row">
          <md-radio
            v-model="destinationType"
            class="width180"
            value="deletePermanently"
            @change="isDirty = true"
          >
            Delete Permanently
          </md-radio>
          <md-radio v-model="destinationType" value="moveToFolder" @change="isDirty = true">
            Move to Folder
          </md-radio>

          <VasionInput
            id="destination-folder"
            v-model="selectedDestinationFolder"
            :readonly="true"
            title="DESTINATION FOLDER"
            inputType="top-white"
            name="destination-folder"
            placeholder="Select Folder..."
            :width="'306'"
            :isDisabled="destinationType === 'deletePermanently'"
            :isInErrorState="processFolderError"
            @input="isDirty = true"
          />
          <VasionButton
            id="destination-folder-button"
            class="browse-button"
            :classProp="'primary'"
            :isDisabled="destinationType === 'deletePermanently'"
            @vasionButtonClicked="toggleBrowseFolderDialog('destination-folder')"
          >
            Browse
          </VasionButton>
        </div>
        <div class="row">
          <VasionCheckbox
            id="process-uploaded-documents"
            name="process-uploaded-documents"
            :checked="lookInFolder"
            style="padding-right: 44px"
            @change="lookInFolder = !lookInFolder; isDirty = true"
          >
            Look in Folder
          </VasionCheckbox>
          <div class="width180" />
          <VasionInput
            id="look-in-folder"
            v-model="selectedLookInFolder"
            title="LOOK IN FOLDER"
            :readonly="true"
            inputType="top-white"
            name="look-in-folder"
            placeholder="Select Folder..."
            :width="'306'"
            :isDisabled="!lookInFolder"
            @input="isDirty = true"
          />
          <VasionButton
            id="look-in-folders-button"
            class="browse-button"
            :classProp="'primary'"
            :isDisabled="!lookInFolder"
            @vasionButtonClicked="toggleBrowseFolderDialog('look-in-folder'); showOtherFolders = false"
          >
            Browse
          </VasionButton>
        </div>
        <div class="row">
          <VasionCheckbox
            id="process-uploaded-documents"
            name="process-uploaded-documents"
            :checked="onlyProcessUploadedDocuments"
            style="padding-right: 10px"
            @change="onlyProcessUploadedDocuments = !onlyProcessUploadedDocuments; isDirty = true"
          >
            Only Process Uploaded Documents
          </VasionCheckbox>
        </div>
      </div>
      <div v-if="showMainSection" id="comparing-criteria" class="collapsable-section">
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showComparingCriteria ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="showComparingCriteria = !showComparingCriteria"
        />
        <h2 class="subheader">
          Comparing Criteria
        </h2>
      </div>
      <div v-if="showMainSection" v-show="showComparingCriteria">
        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="comparingCriteriaField"
            :dataArray="comparingCriteriaList"
            width="392"
            title="COMPARING CRITERIA"
            placeholder="Select Criteria..."
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="isDirty = true"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div class="row">
          <md-radio
            v-model="periodDate"
            class="width180"
            value="periodDate"
            @change="isDirty = true"
          >
            Period Date
          </md-radio>
          <md-radio v-model="periodDate" value="date" @change="isDirty = true">
            Date
          </md-radio>
        </div>
      </div>
      <div v-if="showMainSection" id="comparing-criteria" class="collapsable-section">
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showPeriodFromCurrentDate ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="showPeriodFromCurrentDate = !showPeriodFromCurrentDate"
        />
        <h2 class="subheader">
          {{ dateCollapsableSectionName }}
        </h2>
      </div>
      <div v-if="showMainSection" v-show="showPeriodFromCurrentDate">
        <div v-if="periodDate === 'periodDate'" class="row">
          <VasionInput
            id="years"
            v-model="years"
            inputType="top-white"
            name="years"
            title="YEARS"
            specialType="number"
            style="padding-right: 13px"
            :width="'100'"
            @input="isDirty = true"
          />
          <VasionInput
            id="months"
            v-model="months"
            inputType="top-white"
            name="months"
            title="MONTHS"
            specialType="number"
            style="padding-right: 13px"
            :width="'100'"
            @input="isDirty = true"
          />
          <VasionInput
            id="days"
            v-model="days"
            inputType="top-white"
            name="days"
            title="DAYS"
            specialType="number"
            style="padding-right: 13px"
            :width="'100'"
            @input="isDirty = true"
          />
        </div>
        <div v-if="periodDate === 'date'" class="row">
          <VasionDateTimeField
            v-model="dateField"
            label="DATE"
            type="date"
            width="300px"
            :required="true"
          />
        </div>
      </div>
      <div v-if="showMainSection" id="comparing-criteria" class="collapsable-section">
        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showStringFieldComparison ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="showStringFieldComparison = !showStringFieldComparison"
        />
        <h2 class="subheader">
          String Field Comparison
        </h2>
      </div>
      <div v-if="showMainSection" v-show="showStringFieldComparison">
        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="attributeForm"
            :dataArray="attributeFormList"
            width="302"
            :title="`${$formsLabel.toUpperCase()}`"
            placeholder="..."
            type="plain-list"
            displayName="name"
            :openAbove="true"
            style="padding-right: 13px"
            valueName="value"
            @input="handleAttributeFormChange(); isDirty = true"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="stringComparisonField"
            title="FIELD"
            :dataArray="stringIndexFields"
            :showSearchField="true"
            :openAbove="true"
            style="padding-right: 13px"
            width="302"
            type="plain-list"
            displayName="name"
            valueName="value"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="stringComparison"
            title="COMPARISON"
            :dataArray="stringComparisonList"
            :showSearchField="true"
            :openAbove="true"
            style="padding-right: 13px"
            width="302"
            type="plain-list"
            displayName="name"
            valueName="id"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionInput
            id="value"
            v-model="stringComparisonValue"
            title="VALUE"
            inputType="top-white"
            name="value"
            placeholder="Enter Value..."
            :width="'306'"
            @input="isDirty = true"
          />
        </div>
      </div>
    </div>
    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'CONFIRM'"
      :modalType="'slot'"
      :sync="showBrowseFolder"
      @confirmButtonClick="folderDialogOK()"
      @noButtonClick="showBrowseFolder = false; showOtherFolders = true"
    >
      <div id="browse-folders-container">
        <VasionFolders
          :onlyShowStorageConfigId="showOtherFolders ? 0 : null"
          @itemSelected="folderSelected = true"
        />
      </div>
    </VasionGeneralModal>
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
    <md-dialog id="confirmDeletePermanently" :md-active.sync="showDeletePermanentlyWarningDialog">
      <md-dialog-title>Warning: Permanent Deletion</md-dialog-title>
      <md-dialog-content class="warning-dialog-content">
        <p>You are about to configure a retention scheduler to <strong>permanently delete documents</strong>.<br>All the documents that meet the specified criteria will be <strong>irreversibly deleted</strong>.<br>We strongly recommend running a test with sample files before proceeding with larger data sets to ensure the desired outcome.</p>
        <p><strong>Are you sure you want to proceed with these changes?</strong></p>
      </md-dialog-content>
      <md-dialog-actions>
        <VasionButton classProp="secondary" class="mr-8px" @vasionButtonClicked="cancelDeletePermanently">Cancel</VasionButton>
        <VasionButton classProp="primary" @vasionButtonClicked="acceptDeletePermanently">Confirm</VasionButton>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditRetention',
components: {
    AutomationEngineSharedHeader,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data() {
    return {
      attributeForm: '',
      comparingCriteriaField: '',
      comparingCriteriaValues: [],
      dateField: '',
      documentWithDateField: null,
      days: 0,
      destinationType: 'moveToFolder',
      documentWithType: 'dateField',
      documentWithObject: '',
      folderSelected: false,
      indexFields: [],
      indexFieldsForDate: [],
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      lookInFolder: false,
      months: 0,
      onlyProcessUploadedDocuments: false,
      periodDate: 'periodDate',
      processFolderError: false,
      schedulerId: 0,
      schedulerTitle: '',
      selectedDestinationFolderId: null,
      selectedDestinationFolder: '',
      selectedLookInFolderId: null,
      selectedLookInFolder: '',
      showBrowseFolder: false,
      showBuildRetention: false,
      showComparingCriteria: false,
      showDeletePermanentlyWarningDialog: false,
      showLeaveDialog: false,
      showMainSection: true,
      showOtherFolders: true,
      showPeriodFromCurrentDate: false,
      showSnackbarBool: false,
      showStringFieldComparison: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      splitterValues: [{ message: '' }],
      stringComparisonList: [
        {
          name: '[None]',
          id: -1,
        },
        {
          name: 'Equals',
          id: 0,
        },
        {
          name: 'Contains',
          id: 1,
        },
        {
          name: 'Starts With',
          id: 2,
        },
        {
          name: 'Ends With',
          id: 3,
        },
      ],
      stringComparison: '',
      stringComparisonValue: '',
      stringComparisonField: '',
      years: 0,
    }
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    comparingCriteriaList() {
      return this.comparingCriteriaValues.map(c => {
        return {
          name: c.sName,
          value: c.iID,
        }
      })
    },
    dateCollapsableSectionName() { return this.periodDate === 'periodDate' ? 'Period From Current Date' : 'Date' },
    dateIndexFields() { return this.indexFieldsForDate.filter(i => i.type === 3) },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    stringIndexFields() { return this.indexFields.filter(i => i.type === 1 || i.type === 2) },
  },
  watch: {
    attributeForm: async function () {
      this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.attributeForm.value)
    },
    destinationType: function () {
      if (this.destinationType === 'deletePermanently' && this.isDirty) {
        this.showDeletePermanentlyWarningDialog = true
      }
    },
    documentWithObject: async function () {
      this.indexFieldsForDate = await this.$store.dispatch('common/getIndexFieldsForForm', this.documentWithObject?.value)
      this.documentWithDateField = {}
    },
  },
  async created() {
    this.isLoading = true
    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('common/getIndexFieldsForForm', 0),
      this.$store.dispatch('admin/getLookupBindings'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.indexFieldsForDate = promises[1]
    // eslint-disable-next-line prefer-destructuring
    this.indexFields = promises[1]
    // eslint-disable-next-line prefer-destructuring
    this.comparingCriteriaValues = promises[2]

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      const data = await this.$store.dispatch('automationEngine/getRetentionScheduler', parseInt(this.$route.params.schedulerId, 10))
      this.schedulerTitle = data.runDetails.name
      this.setHeaderDetails(data.runDetails)
      this.setSchedulerValues(data)
    } else {
      this.schedulerTitle = 'Untitled Retention'
    }
    this.isLoading = false
  },
  methods: {
    acceptDeletePermanently() {
      this.showDeletePermanentlyWarningDialog = false
    },
    cancelDeletePermanently() {
      this.destinationType = 'moveToFolder'
      this.showDeletePermanentlyWarningDialog = false
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ name: this.routeTo.name })
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.item.FolderId > 0 && this.selectedFolder.isSelected === true) {
        if (this.fileBrowseField === 'destination-folder') {
          this.selectedDestinationFolderId = this.selectedFolder.item.FolderId
          this.selectedDestinationFolder = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'look-in-folder') {
          this.selectedLookInFolderId = this.selectedFolder.item.FolderId
          this.selectedLookInFolder = this.selectedFolder.item.Name
          this.showOtherFolders = !this.showOtherFolders
        }
      } else {
        this.selectedProcessFolderId = null
        this.selectedProcessFolder = ''

        this.selectedDestinationFolderId = null
        this.selectedDestinationFolder = ''
      }

      this.showBrowseFolder = false
      this.$store.dispatch('common/setSelectedFolder', null)
    },
    goToRetentionList() { this.$router.push({ name: 'Retention' }) },

    async save() {
      this.isLoading = true
      const { header } = this.$refs

      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          lookInFolderID: this.lookInFolder ? this.selectedLookInFolderId : null,
          moveToFolderID: this.destinationType === 'moveToFolder' ? this.selectedDestinationFolderId : null,
          objectID: this.documentWithType === 'dateField' ? this.documentWithObject?.value : null,
          documentWithDateFieldName: this.documentWithObject?.value && this.documentWithDateField ? this.documentWithDateField.value : null,
          onlyProcessUploadedDocuments: this.onlyProcessUploadedDocuments,
          comparingCriteriaID: this.comparingCriteriaField?.value ? this.comparingCriteriaField.value : null,
          compareFromPeriodDate: this.periodDate === 'periodDate',
          compareFromDateYears: this.periodDate === 'periodDate' ? this.years : 0,
          compareFromDateMonths: this.periodDate === 'periodDate' ? this.months : 0,
          compareFromDateDays: this.periodDate === 'periodDate' ? this.days : 0,
          compareDate: this.dateField,
          stringComparisonObjectID: this.attributeForm?.value ? this.attributeForm.value : null,
          stringComparisonFieldName: this.attributeForm?.value && this.stringComparisonField ? this.stringComparisonField.value : null,
          stringComparisonTypeID: this.attributeForm?.value && this.stringComparison ? this.stringComparison.id : null,
          stringComparisonValue: this.attributeForm?.value ? this.stringComparisonValue : null,
        }
        const response = await this.$store.dispatch('automationEngine/saveRetentionScheduler', payload)
        this.isLoading = false
        if (response > 0) {
          this.snackbarTitle = 'Success'
          this.snackbarSubTitle = 'Retention Scheduled'
          this.snackbarImage = true
          this.showSnackbarBool = true
          this.isDirty = false
          setTimeout(() => {
            this.goToRetentionList()
          }, 1500)
          return
        } else {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = 'There was an error saving '
          this.snackbarImage = false
          this.showSnackbarBool = true
        }
      }
      this.isLoading = false
    },
    setHeaderDetails(details) {
      const { header } = this.$refs
      header.setValues(details)
    },
    async setSchedulerValues(data) {
      this.selectedLookInFolderId = data.lookInFolderID
      this.selectedLookInFolder = data.lookInFolderName
      this.selectedDestinationFolderId = data.moveToFolderID
      this.selectedDestinationFolder = data.moveToFolderName
      this.lookInFolder = data.lookInFolderID !== null
      this.destinationType = this.selectedDestinationFolderId !== null ? 'moveToFolder' : 'deletePermanently'
      this.documentWithObject = data.objectID
      this.documentWithType = this.documentWithObject !== null ? 'dateField' : 'createdDate'
      if (this.documentWithObject)
        this.indexFieldsForDate = await this.$store.dispatch('common/getIndexFieldsForForm', this.documentWithObject)

      this.documentWithDateField = this.dateIndexFields.find(a => a.value === data.documentWithDateFieldName)
      this.onlyProcessUploadedDocuments = data.onlyProcessUploadedDocuments
      this.comparingCriteriaField = this.comparingCriteriaList.find(c => c.value === data.comparingCriteriaID)
      this.dateField = data.compareDate ? data.compareDate : ''
      this.periodDate = this.dateField !== null ? 'date' : 'periodDate'
      this.years = data.compareFromDateYears
      this.months = data.compareFromDateMonths
      this.days = data.compareFromDateDays
      this.attributeForm = this.attributeFormList.find(a => a.value === data.stringComparisonObjectID)
      this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.attributeForm.value)
      this.stringComparison = this.stringComparisonList.find(a => a.id === data.stringComparisonTypeID)
      this.stringComparisonValue = data.stringComparisonValue
      this.stringComparisonField = this.stringIndexFields.find(i => i.value === data.stringComparisonFieldName)
    },

    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFolder = !this.showBrowseFolder

      this.fileBrowseField = ''
      if (this.showBrowseFolder && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors} \n`
      }
      if (this.destinationType === 'moveToFolder' && this.selectedDestinationFolderId === null) {
        error = true
        errorText = `${errorText} - Please Select a Destination Folder`
      }
      if (this.documentWithType === 'dateField' && this.documentWithObject === '') {
        error = true
        errorText = `${errorText} - Please Select an Object for the Date Field \n`
      }
      if (this.lookInFolder && this.selectedLookInFolderId === null) {
        error = true
        errorText = `${errorText} - Please Select a Look In Folder \n`
      }
      if (this.comparingCriteriaField === '') {
        error = true
        errorText = `${errorText} - Please select a Comparing Criteria`
      }

      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = errorText
        this.snackbarImage = false
        this.showSnackbarBool = true
        return false
      }
      return true
    },
    handleAttributeFormChange(){
      this.stringComparisonField = {}
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .collapsable-section {
    display: flex;
    flex-direction: row;

    .subheader {
      @include SubHeadline;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

  .collapse-section {
    background-color: $grey-50;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    margin: 0 16px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .collapse-section:hover {
      border-radius: 8px;
      background-color: $grey-200;
    }

    .date {
      width: 300px;
    }

  .row{
    display: flex;
    flex-direction: row;
    margin: 0px;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
  }
  .radio-label {
    margin-top: 10px;
    margin-bottom: -18px;
  }
  .column{
    display: flex;
    flex-direction: column;
  }
  .width180 {
    width: 180px;
  }

  .row::after,
  .row::before {
    display: none;
  }

  .browse-button {
    padding-top: 20px;
  }

  #browse-folders-container {
    width: 400px;
    height: 300px;
    overflow: auto;
  }

  .row-checkbox {
    width: 396px;
  }

  .plus-button, .minus-button {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background-color: $grey-50;
    align-self: start;
  }

  .plus-button {
    position: relative;
    top: 20px;
  }

  .plus-and-minus-disabled {
    background-color: $grey-75;
    fill: $grey-300;
  }

  .plus-button:hover, .minus-button:hover{
    cursor: pointer;
  }
  .plus-and-minus-disabled:hover {
    cursor: default;
  }

    .input-padding {
      padding-right: 15px;
    }
.warning-dialog-content {
  max-width: 450px;
}
.mr-8px {
  margin-right: 8px;
}
</style>
